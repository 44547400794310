import {
    BurnupChartApi,
    CalculatedMetricsApi,
    CalculatedMetricSpecsApi,
    Configuration,
    DataPointsApi,
    FetchParams,
    LocationApi,
    ProjectApi,
    ProvidedMetricsApi,
    ProvidedMetricSpecsApi,
    RequestContext,
    ResponseContext,
    SprintApi,
} from './openapi';
import config from '../../services/config';
import { setMetadata } from '../../services/metadataCache';
import { ValidationError } from '../../services/errors/ValidationError';
import { AuthorizationError } from '../../services/errors/AuthorizationError';
import { AuthenticationError } from '../../services/errors/AuthenticationError';
import {Providers} from "../../authentication/tokenProvider";

const configuration = new Configuration({
    basePath: config.get('REACT_APP_SPRING_API_URL'),
    middleware: [
        { pre: setAuthorization },
        { post: manageResponse },
        { post: setReleaseAndBuild },
    ],
});

async function setAuthorization({
    url,
    init,
}: RequestContext): Promise<FetchParams> {
    const token = await Providers.applicationApiProvider.getToken();
    if (!token) {
        throw new Error('No Auth Token');
    }
    init.headers = {
        ...init.headers,
        Authorization: `Bearer ${token.value()}`,
    };

    return {
        init,
        url,
    };
}

async function manageResponse({
    response,
}: ResponseContext): Promise<Response> {
    if (!response.ok) {
        if (response.status === 404) {
            return response.json();
        }
        if (response.status === 400) {
            return response.json().then(json => {
                throw new ValidationError(
                    json.error.message,
                    json.error.errors,
                );
            });
        }
        if (response.status === 401) {
            // user should authenticate
            throw new AuthorizationError(response.statusText);
        }
        if (response.status === 403) {
            // user can't perform this action
            throw new AuthenticationError(response.statusText);
        }
        throw Error(response.statusText);
    }
    return response;
}

async function setReleaseAndBuild({
    response,
    response: { headers },
}: ResponseContext): Promise<Response> {
    setMetadata(
        'REACT_API_RELEASENAME',
        headers.get('X-RELEASE-NAME') ?? 'not set',
    );
    setMetadata(
        'REACT_API_BUILDNUMBER',
        headers.get('X-BUILD-NUMBER') ?? 'not set',
    );
    return response;
}

export const burnupChartApi = new BurnupChartApi(configuration);
export const projectApi = new ProjectApi(configuration);
export const dataPointApi = new DataPointsApi(configuration);
export const sprintApi = new SprintApi(configuration);
export const locationApi = new LocationApi(configuration);
export const providedMetricsApi = new ProvidedMetricsApi(configuration);
export const providedMetricsSpecsApi = new ProvidedMetricSpecsApi(
    configuration,
);
export const calculatedMetricsSpecsApi = new CalculatedMetricSpecsApi(
    configuration,
);
export const calculatedMetricsApi = new CalculatedMetricsApi(configuration);
